import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Torvalds mergade Wireguard i 5.6: `}<a parentName="li" {...{
          "href": "https://github.com/torvalds/linux/commit/bd2463ac7d7ec51d432f23bf0e893fb371a908cd"
        }}>{`https://github.com/torvalds/linux/commit/bd2463ac7d7ec51d432f23bf0e893fb371a908cd`}</a></li>
      <li parentName="ul">{`FOSDEM 2020 gick i helgen: `}<a parentName="li" {...{
          "href": "https://fosdem.org/2020/"
        }}>{`https://fosdem.org/2020/`}</a></li>
      <li parentName="ul">{`Plasma 5.18 (LTS) närmar sig: `}<a parentName="li" {...{
          "href": "https://pointieststick.com/2020/02/01/this-week-in-kde-plasma-5-18-is-nigh/"
        }}>{`https://pointieststick.com/2020/02/01/this-week-in-kde-plasma-5-18-is-nigh/`}</a></li>
      <li parentName="ul">{`IBM byter VD: `}<a parentName="li" {...{
          "href": "https://www.bloomberg.com/news/articles/2020-01-30/ibm-names-arvind-krishna-as-ceo-rometty-to-retire-at-year-s-end"
        }}>{`https://www.bloomberg.com/news/articles/2020-01-30/ibm-names-arvind-krishna-as-ceo-rometty-to-retire-at-year-s-end`}</a></li>
      <li parentName="ul">{`Kraftfull laptop med Kubuntu installerat: `}<a parentName="li" {...{
          "href": "https://kfocus.org/"
        }}>{`https://kfocus.org/`}</a><ul parentName="li">
          <li parentName="ul">{`från \\$1,795.00`}</li>
          <li parentName="ul">{`Lenovo skeppar workstation med Ubuntu installerat: `}<a parentName="li" {...{
              "href": "https://www.lenovo.com/us/en/think-workstations/thinkstation-p-series-towers/ThinkStation-P520/p/33TS3TPP520"
            }}>{`https://www.lenovo.com/us/en/think-workstations/thinkstation-p-series-towers/ThinkStation-P520/p/33TS3TPP520`}</a><ul parentName="li">
              <li parentName="ul">{`från \\$6,029.10..`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Podverse, open source podcast player med clip-and-share-funktion: `}<a parentName="li" {...{
          "href": "https://github.com/podverse"
        }}>{`https://github.com/podverse`}</a></li>
      <li parentName="ul">{`CERN flyttar från Facebook Workplace till open source Mattermost och Discourse: `}<a parentName="li" {...{
          "href": "https://home.cern/news/news/computing/cern-ends-trial-facebook-workplace"
        }}>{`https://home.cern/news/news/computing/cern-ends-trial-facebook-workplace`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Uppdateringen av Alex Lenovo Tablet...`}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`ProtonDB: `}<a parentName="li" {...{
          "href": "https://www.protondb.com"
        }}>{`https://www.protondb.com`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Tullad`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Manjaro + Plasma`}<ul parentName="li">
          <li parentName="ul">{`Download page lite weird`}</li>
          <li parentName="ul">{`problem med installer - formaterade hdd:n`}</li>
          <li parentName="ul">{`package manager "pacmac" amazing. Utbudet great.`}</li>
          <li parentName="ul">{`minimize-knapp + fönsterkontroller på högersidan 🤩`}</li>
          <li parentName="ul">{`audio manager-funktionen verkar grym`}<ul parentName="li">
              <li parentName="ul">{`scroll-bug, men kommer ny version i nästa uppdatering`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Vi finns i fediversumet nu!`}</li>
      <li parentName="ul">{`Recap på Y2K2X-buggen från första avsnittet (tack @rgggn): `}<a parentName="li" {...{
          "href": "https://it-ord.idg.se/ord/y2k2x/"
        }}>{`https://it-ord.idg.se/ord/y2k2x/`}</a></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      